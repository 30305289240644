@keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
.rai-dots .rai-circle {
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  animation-name: dots-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-dots .rai-circle:last-child {
    margin-right: 0; }

@keyframes levels-pulse {
  0% {
    height: 30%;
    opacity: 0.5; }
  20% {
    height: 100%;
    opacity: 1; }
  35% {
    height: 30%;
    opacity: 0.5; }
  55% {
    height: 100%;
    opacity: 1; }
  70% {
    height: 50%;
    opacity: 0.5; }
  80% {
    height: 100%;
    opacity: 1; }
  100% {
    height: 30%;
    opacity: 0.5; } }
.rai-levels .rai-levels-container {
  text-align: left;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  animation-duration: inherit; }
.rai-levels .rai-bar {
  bottom: 0;
  display: inline-block;
  vertical-align: bottom;
  width: 0.25em;
  background: #727981;
  margin-right: 0.125em;
  height: 50%;
  animation-name: levels-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-levels .rai-bar:last-child {
    margin-right: 0; }

@keyframes rai-sentry-pulse {
  from {
    transform: scale(0);
    opacity: 1; }
  to {
    transform: scale(1);
    opacity: 0; } }
.rai-sentry {
  text-align: left;
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em; }
  .rai-sentry .rai-wave-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-duration: inherit; }
  .rai-sentry .rai-wave {
    display: inline-block;
    border: 0.125em solid #727981;
    border-radius: 100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    animation-name: rai-sentry-pulse;
    animation-duration: inherit;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

@keyframes rai-spinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
.rai-spinner {
  width: 1.5em;
  height: 1.5em;
  position: relative;
  margin: 0; }
  .rai-spinner-inner, .rai-spinner-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    margin-left: -0.0625em;
    margin-top: -0.0625em; }
  .rai-spinner-outer {
    border: 0.125em solid #727981;
    opacity: 0.2; }
  .rai-spinner-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-top: 0.125em solid #727981;
    border-right: 0.125em solid transparent !important;
    border-bottom: 0.125em solid transparent !important;
    border-left: 0.125em solid transparent !important;
    animation-name: rai-spinner;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: inherit; }

@keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
.rai-squares .rai-square {
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  animation-name: dots-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-squares .rai-square:last-child {
    margin-right: 0; }

@keyframes digital-pulse {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }
.rai-digital {
  height: 1em;
  text-align: center; }
  .rai-digital > div {
    display: inline-block;
    margin-right: 0.125em;
    background-color: #727981;
    width: 0.25em;
    height: 100%;
    animation-name: digital-pulse;
    animation-duration: inherit;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }
    .rai-digital > div:last-child {
      margin-right: 0; }

@keyframes bounce-pulse {
  0% {
    transform: translateY(0.1875em); }
  30% {
    transform: translateY(-0.25em); }
  50% {
    transform: translateY(3); } }
.rai-bounce > div {
  transform: translateY(0.1875em);
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  animation-name: bounce-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-bounce > div:last-child {
    margin-right: 0; }

@keyframes windill-pulse {
  0% {
    transform: rotate(0deg); }
  99% {
    transform: rotate(356deg); } }
.rai-windill {
  width: 1em;
  height: 1em;
  position: relative; }
  .rai-windill > div {
    border-radius: 0.125em;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -0.0625em;
    width: 0.125em;
    height: 100%;
    animation-name: windill-pulse;
    animation-duration: inherit;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
